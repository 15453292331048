<template>
  <div class="view flex flex-d ai-c">
    <headers styleSize="min" :times="headTime"></headers>
    <img src="@/assets/speaial_activity_img/image/time_title.png" class="time-title" alt="">
    <TitleAndTime :time="dataTime">
      <img src="@/assets/speaial_activity_img/image/eighttitleen.png" class="title" alt="">
    </TitleAndTime>
    <div class="main flex flex-1 jc-c">
      <div class="left pie-view">
        <img src="@/assets/speaial_activity_img/image/pie_bg.png" alt="">
        <div class="value">
          <p>新品占比</p>
          <p class="num">{{newSkuProportion}}%</p>
        </div>
      </div>
      <div class="right flex flex-d jc-sb">
        <div>
          <div class="sub-title flex ai-c">
            <img class="icon-title" src="@/assets/speaial_activity_img/image/icon.png">
            <span>参与双十一大促的品牌个数</span>
          </div>
          <div class="number">{{ streamSalesFormatted }}</div>
        </div>
        <div>
          <div class="sub-title flex ai-c">
            <img class="icon-title" src="@/assets/speaial_activity_img/image/icon.png">
            <span>参与双十一大促的SKU个数</span>
          </div>
          <div class="number">{{skuNumFormatted }}</div>
        </div>
      </div>
    </div>
    <Annotation />
  </div>
</template>

<script>
import Headers from '@/components/Big/Header.vue'
import Annotation from '@/components/Big/Annotation'
import TitleAndTime from '@/components/Big/TitleAndTime'
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {
          brandNum: '',
          skuNum: '',
          newSkuProportion: '',
        }
      },
    },
  },
  components: {
    Headers,
    Annotation,
    TitleAndTime,
  },
  watch: {
    data: {
      handler(newValue, oldValue) {
        // console.log('data8', newValue)
        this.dataTime = newValue.time + '数据时段'
      },
      immediate: true,
    },
  },
  data() {
    return {
      headTime: '',
      dataTime: '',
      eightNum: '0',
      eightSku: '0',
    }
  },

  computed: {
    newSkuProportion() {
      return (parseFloat(this.data.newSkuProportion) * 100).toFixed(2)
    },
    streamSalesFormatted() {
      return this.data.brandNum
    },
    skuNumFormatted() {
      return this.data.skuNum
    },
  },
  mounted() {},
  methods: {},
}
</script>

<style lang="scss" scoped>
@import '@/style/rem.scss';
@import './style/zn.scss';
</style>
